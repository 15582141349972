import { assessmentItemType, observationRole } from '../learnableMoments';

const env = process.env.REACT_APP_ENV || process.env.NODE_ENV

/**
 * Important: if you change something here you most likely have to change something in survey.service.js AND in the related limesurvey survey.
 */
const baseExternalIds = [];
if (env !== 'production') {
  baseExternalIds.push('23190');
  baseExternalIds.push('1001');
}

/**
 * TODO:
 * get from akzento-plus
 * - all courses of current semester
 *  - with meta.tools.360grad !== undefined
 *  - group by meta.tools.360grad.course
 *    - contains: name, title, assessmentItems
 */

export const courses = [
  {
    // Medical Skills Lab - Termin 1..6, 8..12
    // LimeSurvey: KA
    id: '1',
    externalIds: env === 'production' ? ['27138', '27139', '27152', '27153', '27156', '27167', '27168', '27171', '27184', '27185', '27187'] : baseExternalIds,
    name: 'anamnesis-training',
    title: 'Anamnese',
    assessmentItems: ['nepa-a']
  },
  {
    // Medical Skills Lab - Visitentraining I, Praktikum Medical Skills Lab - Übergabetraining Tag 1, Praktikum Medical Skills Lab - Übergabetraining Tag 2
    // LimeSurvey: KVT
    id: '2',
    externalIds: ['27183', '27143', '27142'],
    name: 'ward-round-training',
    title: 'Visitentraining',
    assessmentItems: ['nepa-au']
  },
  // {
  //   id: '3',
  //   externalIds: [], // TODO
  //   name: 'medical-thinking-and-acting',
  //   title: 'Ärztliches Denken und Handeln',
  //   assessmentItem s: ['nepa-au']
  // },
  {
    // Praktikum der Präventionsmedizin - Raucherentwöhnung
    // LimeSurvey: KPM
    id: '4',
    externalIds: ['25765'],
    name: 'preventive-medicine',
    title: 'Präventionsmedizin',
    assessmentItems: ['nepa-pm'],
  },
  // {
  //   id: '5',
  //   externalIds: [], // TODO
  //   name: 'emergency-medicine-2',
  //   title: 'Notfallmedizin 2',
  //   assessmentItems: ['nepa-em2']
  // },
  // {
  //   id: '6',
  //   externalIds: [], // TODO
  //   name: 'surgeon-basic-skills-1',
  //   title: 'Chirurgische Basisfertigkeiten',
  //   assessmentItems: ['nepa-cb1']
  // }
]

export const conceptualFrameworkItems = [
  {
    id: 'nepa-a',
    title: 'Eine fokussierte Anamnese bei einem erwachsenen Patienten durchführen.',
    type: assessmentItemType.NEPA
  },
  {
    id: 'nepa-au',
    title: 'Eine fokussierte Anamnese und Untersuchung bei einem erwachsenen Patienten durchführen.',
    type: assessmentItemType.NEPA
  },
  {
    id: 'nepa-pm',
    title: 'Eine Kurzintervention zur Tabakentwöhnung anhand der <em>5-A-Strategie</em> durchführen.',
    type: assessmentItemType.NEPA
  },
  {
    id: 'nepa-em2',
    title: 'Eine Notfallbehandlung und Koordination der Versorgung kritisch kranker Patienten durchführen.',
    type: assessmentItemType.NEPA
  },
  {
    id: 'nepa-cb1',
    title: 'Eine fokussierte Anamnese und Untersuchung bei einem Patienten mit adominellen Beschwerden durchführen.',
    type: assessmentItemType.NEPA
  }
]

export const rooms = [
  {id: '1', title: 'Seminarraum 1', roles: [observationRole.TUTOR], defaultSpRoomId: null},
  {id: '2', title: 'Seminarraum 2', roles: [observationRole.TUTOR], defaultSpRoomId: null},
  {id: '3', title: 'Seminarraum 3', roles: [observationRole.TUTOR], defaultSpRoomId: null},
  {id: '4', title: 'Seminarraum 4', roles: [observationRole.TUTOR], defaultSpRoomId: null},
  {id: '5', title: 'Seminarraum 5', roles: [observationRole.TUTOR], defaultSpRoomId: null},
  {id: '6', title: 'Seminarraum 6', roles: [observationRole.TUTOR], defaultSpRoomId: null},
  {id: '7', title: 'Seminarraum 7', roles: [observationRole.TUTOR], defaultSpRoomId: null},
  {id: '8', title: 'Seminarraum 8', roles: [observationRole.TUTOR], defaultSpRoomId: null},
  {id: '9', title: 'Patientenzimmer Intensiv 1', roles: [observationRole.SP]},
  {id: '10', title: 'Patientenzimmer Intensiv 2', roles: [observationRole.SP]},
  {id: '11', title: 'Patientenzimmer 3', roles: [observationRole.SP]},
  {id: '12', title: 'Patientenzimmer 4', roles: [observationRole.SP]},
  {id: '13', title: 'Patientenzimmer 5', roles: [observationRole.SP]},
  {id: '14', title: 'Patientenzimmer 6', roles: [observationRole.SP]},
  {id: '15', title: 'Praxis A', roles: [observationRole.SP]},
  {id: '16', title: 'Praxis B', roles: [observationRole.SP]},
  {id: '17', title: 'Praxis C', roles: [observationRole.SP]},
  {id: '18', title: 'Praxis D', roles: [observationRole.SP]},
]

const getByTitle = (list, title = '') => list.find(i => i.title.toLowerCase() === title.toLowerCase())
export const getCourseByTitle = title => getByTitle(courses, title)
export const getRoomByTitle = title => getByTitle(rooms, title)

export const getGroupByTitle = title => {
  const res = /^([1-6]?)-h([0-9]+)$/g.exec(title);
  if (!res) {
    return;
  }
  // ID from AKzento for a specific clinical group (in the clinical phase).
  return `3.20${res[1]}.H${res[2]}`
}